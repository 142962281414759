import React from "react";
import {BPGuiSettings} from "../../../services/api/partner_config";
import {PoweredByRB} from "./PoweredByRB";

interface FooterTemplate {
    styles: any;
    content: BPGuiSettings
}

export function FooterTemplate (props: FooterTemplate) {

    const getCurrentYear = () => {
        const year = new Date().getFullYear();
        return year.toString();
    };

    const {styles, content} = props;
    const currentYear = getCurrentYear();


    const getPartnerLegalText = () => {
        return content.content.footer.legalNotice.replace("<current_year>", currentYear);
    };

    const getColumnSection = () => {
        return content.content.footer.mainSection.length ? (
            <section className={`ft-main ft-columns-cont ${styles.main}`}>
                {
                    content.content.footer.mainSection.map(section => {
                        return <div className="ft-main-item" key={section.title}>
                            <h2 className="ft-title">{section.title}</h2>
                            <ul>
                                {
                                    section.items.map(item => {
                                        return <li key={item.label}><a href={item.url}>{item.label}</a></li>
                                    })
                                }
                            </ul>
                        </div>
                    })
                }
            </section>
        ) : null;
    };

    return <div className="rb__footer--container" >
        {
            getColumnSection()
        }
        <section className={`ft-partner-legal ${styles.legal}`}>
            <div className="ft-main-item" />
            <div className="ft-main-item legal-cont">{getPartnerLegalText()}</div>
            <div className="ft-main-item">
                <PoweredByRB />
            </div>
        </section>
        <section className="ft-legal" >
            <div className="copyright" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <span>&copy; {currentYear} Rental Beast, all rights reserved.</span>
                <a href="/privacy.pdf" target="_blank">Privacy Policy</a>
                <span>|</span>
                <a href="/terms.pdf" target="_blank">Terms of Use</a> */}
                {/* ---- New footer ---- */}
                <p>Rental Beast, Inc. © {currentYear}. All rights reserved.</p>
                <p><a href="https://rentalbeast.com/legal/privacy-policy" target="_blank">Privacy Policy</a>| <a href="https://rentalbeast.com/legal/terms-of-use" target="_blank">Terms of Use</a></p>
                <p style={{ marginTop: 20 }}>222 Third Street, Suite 2240</p>
                <p>Cambridge, MA 02142</p>
                <p>(617) 623-5700 | <a style={{ textDecoration: 'underline' }} href="mailto:applications@rentalbeast.com">applications@rentalbeast.com</a></p>
                {/* ---- END ---- */}
            </div>
        </section>

    </div>
}